









































































import { Vue, Component } from "vue-property-decorator";
import { api } from "@/api";

@Component
export default class Dashboard extends Vue {
  newPassword = "";
  newPasswordCheck = "";
  passwordsMatch = true;
  passwordEmpty = false;
  apiError = false;
  confirmed = false;

  async submit() {
    this.apiError = false;
    this.confirmed = false;

    if (this.newPassword === "") {
      this.passwordEmpty = true;
      return;
    }
    this.passwordEmpty = false;

    if (this.newPassword !== this.newPasswordCheck) {
      this.passwordsMatch = false;
      return;
    }
    this.passwordsMatch = true;

    try {
      await api.updateMe(this.$store.state.token, {
        password: this.newPassword
      });
    } catch (err) {
      this.apiError = true;
      return;
    }

    this.confirmed = true;
  }
}
